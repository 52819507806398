$(function() {
    $(".flash-message").addClass("active").delay(2000).fadeOut();

    $(".fade-in").addClass("loaded");

    var absURL = window.location.href.split('#')[0].split('?')[0];
    var relURL = absURL.replace(/^(?:\/\/|[^\/]+)*\//, "");

    console.log("rel url: " + relURL);

    var sideMenu = $(".side-menu");
    var searchURL = sideMenu.find("a[href^='/" + relURL + "']");

    if(searchURL.length) {
        sideMenu.find("a[href^='/" + relURL + "']").first().parent("li").addClass("current-page");
    } else if(searchURL.length === 0) {
        var trimmedURL = relURL.substr(0, relURL.lastIndexOf("\/"));
        var searchTrimmedURL = sideMenu.find("a[href^='/" + trimmedURL + "']");

        if(trimmedURL.length) {
            console.log("trimmed url: " + trimmedURL);
            searchTrimmedURL.first().parent("li").addClass("current-page");
        }

        if(searchTrimmedURL.length === 0) {
            var doubleTrimmedURL = trimmedURL.substr(0, trimmedURL.lastIndexOf("\/"));

            if(doubleTrimmedURL.length) {
                var thirdSearchURL = sideMenu.find("a[href^='/" + doubleTrimmedURL + "']");

                console.log("double-trimmed url: " + doubleTrimmedURL);

                thirdSearchURL.first().parent("li").addClass("current-page");
            }

        }
    }

});